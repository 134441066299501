var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateBack
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": _vm.bulkEditCertificates
          }
        }, [_c('span', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(" Rediger mal ")], 1)]), _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "white",
            "outlined": "",
            "disabled": !_vm.hasSelectedUsers
          },
          on: {
            "click": _vm.deleteCertificates
          }
        }, [_c('span', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-trash-can")]), _vm._v(" Slett ")], 1)]), _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "white",
            "disabled": !_vm.hasSelectedUsers,
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openCertificatesModal(_vm.item);
            }
          }
        }, [_c('span', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email")]), _vm._v(" Send ")], 1)]), _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "white",
            "outlined": "",
            "disabled": !_vm.hasSelectedUsers
          },
          on: {
            "click": _vm.generateCertificates
          }
        }, [_c('span', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-flash")]), _vm._v(" Generer ")], 1)])];
      },
      proxy: true
    }])
  }, [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": _vm.closeModal
    }
  }, [_c('EditCourseCertificateModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "existingItemModal": _vm.modalData.existingItem,
      "elementId": _vm.modalData.existingItemId,
      "defaultValues": _vm.modalData.defaultValues,
      "courseId": _vm.courseId
    },
    on: {
      "close": _vm.closeModal,
      "getParticipantCertificatesList": function getParticipantCertificatesList($event) {
        return _vm.getParticipantCertificatesList(true);
      }
    }
  })], 1) : _vm._e(), _vm.bulkEditCertificatesModalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.bulkEditCertificatesModalData.showModal = false;
      }
    }
  }, [_c('EditCourseCertificateModal', {
    attrs: {
      "headline": _vm.bulkEditCertificatesModalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "existingItemModal": _vm.modalData.existingItem,
      "elementId": _vm.modalData.existingItemId,
      "defaultValues": _vm.modalData.defaultValues,
      "courseCustomVariables": _vm.courseCustomVariables,
      "courseId": _vm.courseId,
      "isBulkEdit": ""
    },
    on: {
      "close": function close($event) {
        _vm.bulkEditCertificatesModalData.showModal = false;
      },
      "getParticipantCertificatesList": function getParticipantCertificatesList($event) {
        return _vm.getParticipantCertificatesList(true);
      }
    }
  })], 1) : _vm._e(), _vm.sendCertificateModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    },
    on: {
      "close": function close($event) {
        _vm.sendCertificateModalData.showModal = false;
      }
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.sendCertificateModalData
    },
    on: {
      "close": function close($event) {
        _vm.sendCertificateModalData.showModal = false;
      },
      "submit": _vm.sendCertificates
    }
  }, [_vm._v(" Er du sikker på at du vil sende kursbeviser? "), _c('v-select', {
    staticClass: "my-5",
    attrs: {
      "label": "Velg sendingsmetode",
      "items": _vm.sendMethods,
      "dense": ""
    },
    model: {
      value: _vm.sendMethod,
      callback: function callback($$v) {
        _vm.sendMethod = $$v;
      },
      expression: "sendMethod"
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "title font-weight-light text-center",
    staticStyle: {
      "align-self": "center"
    }
  }, [_c('v-form', [_c('BaseTableFiltered', _vm._g({
    attrs: {
      "headers": _vm.headers,
      "items": _vm.courseParticipants,
      "loading": _vm.loading,
      "itemsPerPage": -1,
      "item-key": "userId",
      "no-data-text": "Ingen deltakere på kurset",
      "show-select": ""
    },
    scopedSlots: _vm._u([{
      key: "item.userFullName",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }, {
      key: "item.certificateStatus",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value ? value : "Ikke generert") + " ")];
      }
    }, {
      key: "item.certificateMessage",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(value ? value : "Ingen melding") + " ")];
      }
    }, {
      key: "item.attendancePercentage",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(value ? value : "0") + "% ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "disabled": !item.certificateFileId
                },
                on: {
                  "click": function click($event) {
                    return _vm.openFilePreviewModalData(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-eye ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis kursbevis")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on,
                  attrs = _ref7.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "disabled": !item.certificateFileId,
                  "data-cy": "viewParticipant-".concat(item.userId)
                },
                on: {
                  "click": function click($event) {
                    return _vm.openEditCourseCertificate(item, "kursbevis for ".concat(item.userFullName), _vm.courseId);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger kursbevis")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on,
                  attrs = _ref8.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "disabled": !item.certificateFileId
                },
                on: {
                  "click": function click($event) {
                    return _vm.downloadCertificate(item.certificateFileId, item.userFullName);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-download ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Last ned kursbevis")])])];
      }
    }], null, true),
    model: {
      value: _vm.selectedStudents,
      callback: function callback($$v) {
        _vm.selectedStudents = $$v;
      },
      expression: "selectedStudents"
    }
  }, _vm.checkHasSelectedUsers()), [_c('v-spacer')], 1), _vm.filePreviewModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1500
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      }
    }
  }, [_c('CourseViewFileModal', {
    attrs: {
      "file": _vm.getFilePreviewData(_vm.filePreviewModalData.existingItem)
    },
    on: {
      "close": function close() {
        return _vm.filePreviewModalData.showModal = false;
      }
    }
  })], 1) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }