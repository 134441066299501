
import {
  ApiCourseCertificateParticipantVariablesDto,
  ApiCourseDefaultVariablesDto,
  ApiGetCourseParticipantCertificateDto,
  ApiUpdateCourseParticipantCertificateDto,
  ApiUpdateCourseParticipatCertificateBulkDto,
  ApiCourseCertificateBulkVariablesDto,
} from "@/api/generated/Api";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { computed, defineComponent, onMounted, PropType, Ref, ref } from "@vue/composition-api";
import { api } from "@/api/api";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline, // @ts-ignore
} from "tiptap-vuetify";

const initialParticipantCertificateValues: () => ApiCourseCertificateParticipantVariablesDto = () => ({
  courseTitle: "",
  courseStartDate: "",
  courseEndDate: "",
  hoursWithInstructor: 0,
  practice: "",
  showAttendance: true,
  showCoursePractice: true,
  location: "",
  curriculumContent: "",
  learningObjectives: "",
});

const initialBulkCertificateValues: () => ApiCourseCertificateBulkVariablesDto = () => ({
  courseTitle: "",
  courseStartDate: "",
  courseEndDate: "",
  hoursWithInstructor: 0,
  practice: "",
  showAttendance: null,
  showCoursePractice: null,
  location: "",
  curriculumContent: "",
  learningObjectives: "",
});

export default defineComponent({
  name: "EditCourseCertificateModal",
  components: { BaseModalForm, TiptapVuetify, BaseDatePicker },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    existingItemModal: {
      type: Object,
    },
    elementId: {
      type: Number,
    },
    defaultValues: {
      type: Object as PropType<ApiCourseDefaultVariablesDto>,
    },
    courseCustomVariables: {
      type: Object as PropType<ApiCourseDefaultVariablesDto>,
    },
    courseId: {
      type: Number,
    },
    isBulkEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["getParticipantCertificatesList"],
  setup(props, { emit }) {
    const route = useRoute();
    const store = useStore<StoreState>();
    const selectedParticipant: Ref<ApiGetCourseParticipantCertificateDto> =
      useAutoDeepCloneProp<ApiGetCourseParticipantCertificateDto>(props, "existingItemModal");
    const participantCertificateValues: Ref<ApiCourseCertificateParticipantVariablesDto> = ref(
      initialParticipantCertificateValues()
    );
    const bulkCertificateValues: Ref<ApiCourseCertificateParticipantVariablesDto> = ref(initialBulkCertificateValues());
    const selectedParticipantCustomValues = ref<ApiCourseCertificateParticipantVariablesDto>();
    const selectedParticipantDefaultValues = ref<ApiCourseCertificateParticipantVariablesDto>();
    const defaultValues: Ref<ApiCourseDefaultVariablesDto> = useAutoDeepCloneProp<ApiCourseDefaultVariablesDto>(
      props,
      "defaultValues"
    );
    const dialog = ref(false);
    const bypassWarning = ref(false);

    const setFormValuesParticipant = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (props.isBulkEdit) {
          return;
        }

        const certificatePartipantData = (await api.course.getCourseParticipantCertificateList(+route.params.id)).data;

        const filteredParticipant = certificatePartipantData?.courseParticipants?.filter(
          (x) => x.userId === selectedParticipant.value.userId
        );

        if (!filteredParticipant) {
          return;
        }

        const [{ customVariables, userDefaultVariables }] = filteredParticipant;

        selectedParticipantCustomValues.value = customVariables;
        selectedParticipantDefaultValues.value = {
          ...userDefaultVariables,
          showAttendance: props.courseCustomVariables?.showAttendance ?? defaultValues.value.showAttendance,
          showCoursePractice: props.courseCustomVariables?.showCoursePractice ?? defaultValues.value.showCoursePractice,
        };

        participantCertificateValues.value = {
          courseTitle: selectedParticipantCustomValues.value?.courseTitle || defaultValues.value.courseTitle,
          userFullName: selectedParticipantCustomValues.value?.userFullName || selectedParticipant.value.userFullName,
          courseStartDate:
            selectedParticipantCustomValues.value?.courseStartDate || defaultValues.value.courseStartDate,
          hoursWithInstructor:
            selectedParticipantCustomValues.value?.hoursWithInstructor || defaultValues.value.hoursWithInstructor,
          practice: selectedParticipantCustomValues.value?.practice || defaultValues.value.practice,
          location: selectedParticipantCustomValues.value?.location || defaultValues.value.location,
          curriculumContent:
            selectedParticipantCustomValues.value?.curriculumContent || defaultValues.value.curriculumContent,
          learningObjectives:
            selectedParticipantCustomValues.value?.learningObjectives || defaultValues.value.learningObjectives,
          userAttendanceHours:
            selectedParticipantCustomValues.value?.userAttendanceHours ||
            selectedParticipantDefaultValues.value?.userAttendanceHours,
          showAttendance:
            selectedParticipantCustomValues.value?.showAttendance ??
            selectedParticipantDefaultValues.value.showAttendance,
          showCoursePractice:
            selectedParticipantCustomValues.value?.showCoursePractice ??
            selectedParticipantDefaultValues.value.showCoursePractice,
        };
      });
    };

    const setFormValuesBulkEdit = () => {
      bulkCertificateValues.value = {
        courseTitle: props.courseCustomVariables?.courseTitle || defaultValues.value.courseTitle,
        courseStartDate: props.courseCustomVariables?.courseStartDate || defaultValues.value.courseStartDate,
        courseEndDate: props.courseCustomVariables?.courseEndDate || defaultValues.value.courseEndDate,
        hoursWithInstructor:
          props.courseCustomVariables?.hoursWithInstructor || defaultValues.value.hoursWithInstructor,
        practice: props.courseCustomVariables?.practice || defaultValues.value.practice,
        showAttendance: props.courseCustomVariables?.showAttendance ?? defaultValues.value.showAttendance,
        showCoursePractice: props.courseCustomVariables?.showCoursePractice ?? defaultValues.value.showCoursePractice,
        location: props.courseCustomVariables?.location || defaultValues.value.location,
        curriculumContent: props.courseCustomVariables?.curriculumContent || defaultValues.value.curriculumContent,
        learningObjectives: props.courseCustomVariables?.learningObjectives || defaultValues.value.learningObjectives,
      };
    };

    onMounted(() => {
      props.isBulkEdit ? setFormValuesBulkEdit() : setFormValuesParticipant();
    });

    const locationItems = [
      { label: "AOF Norge", value: "Oslo" },
      { label: "AOF Vestlandet Agder", value: "Haugesund" },
      { label: "AOF Østfold", value: "Fredrikstad" },
    ];

    const updateParticipantCertificate = (courseId: number) => {
      globalLoadingWrapper({ blocking: false }, async () => {
        if (!courseId) {
          return;
        }

        const payload: ApiUpdateCourseParticipantCertificateDto = {
          variables: {
            userFullName: participantCertificateValues.value.userFullName || null,
            courseEndDate: participantCertificateValues.value.courseEndDate || null,
            courseTitle: participantCertificateValues.value.courseTitle || null,
            courseStartDate: participantCertificateValues.value.courseStartDate || null,
            hoursWithInstructor: participantCertificateValues.value.hoursWithInstructor || null,
            practice: participantCertificateValues.value.practice || null,
            location: participantCertificateValues.value.location || null,
            curriculumContent: participantCertificateValues.value.curriculumContent || null,
            learningObjectives: participantCertificateValues.value.learningObjectives || null,
            userAttendanceHours: participantCertificateValues.value.userAttendanceHours || null,
            showAttendance: participantCertificateValues.value.showAttendance,
            showCoursePractice: participantCertificateValues.value.showCoursePractice,
          },
        };

        await api.course.updateCourseParticipantCertificate(courseId, selectedParticipant.value.userId, payload);

        openNotification(store, NotificationItemType.Success, "Kursbevis oppdatert");
      });

      emit("getParticipantCertificatesList");
      emit("close");
    };

    const rerunUpload = (courseId: number) => {
      bypassWarning.value = true;
      updateBulkCertificates(courseId || 0);
    };

    const updateBulkCertificates = (courseId: number) => {
      if (!bypassWarning.value) {
        dialog.value = true;
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!courseId) {
          return;
        }

        const payload: ApiUpdateCourseParticipatCertificateBulkDto = {
          variables: {
            courseEndDate: bulkCertificateValues.value.courseEndDate || null,
            courseTitle: bulkCertificateValues.value.courseTitle || null,
            courseStartDate: bulkCertificateValues.value.courseStartDate || null,
            hoursWithInstructor: bulkCertificateValues.value.hoursWithInstructor || null,
            practice: bulkCertificateValues.value.practice || null,
            location: bulkCertificateValues.value.location || null,
            curriculumContent: bulkCertificateValues.value.curriculumContent || null,
            learningObjectives: bulkCertificateValues.value.learningObjectives || null,
            showAttendance: bulkCertificateValues.value.showAttendance,
            showCoursePractice: bulkCertificateValues.value.showCoursePractice,
          },
        };

        await api.course.updateCourseParticipantCertificates(courseId, payload);

        openNotification(store, NotificationItemType.Success, "Alle kursbevis oppdatert");
      });

      emit("getParticipantCertificatesList");
      emit("close");
    };

    const handleSubmit = async (courseId: number) => {
      props.isBulkEdit ? updateBulkCertificates(courseId) : updateParticipantCertificate(courseId);
    };

    return {
      readonly: computed(() => props.modalType === ModalType.Display),
      editMode: computed(() => props.modalType === ModalType.Edit),
      templateValues: defaultValues,
      locationItems,
      validateNotEmpty,
      handleSubmit,
      certificateValues: props.isBulkEdit ? bulkCertificateValues : participantCertificateValues,
      dialog,
      rerunUpload,
    };
  },

  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
});
