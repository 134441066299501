var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType,
      "editable": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": function submit($event) {
        return _vm.handleSubmit(_vm.courseId || 0);
      }
    }
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "290"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "red"
    }
  }, [_vm._v("mdi-flag")]), _vm._v("Er du sikker?")], 1), _c('v-card-text', [_vm._v("Du er i ferd med å overskrive kursbevis for alle deltakere")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.rerunUpload(_vm.courseId || 0);
      }
    }
  }, [_vm._v(" Bekreft ")]), _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Avbryt ")])], 1)], 1)], 1), _c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.isBulkEdit ? _c('v-row', [_c('v-col', {
    staticClass: "py-0 font-weight-black"
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "red"
    }
  }, [_vm._v("mdi-flag")]), _vm._v(" NB! Dette vil redigere alle kursbevis! Dette overskriver også de enkle du har redigert før ")], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": _vm.isBulkEdit ? 12 : 6,
      "md": _vm.isBulkEdit ? 12 : 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Kurstittel",
      "required": ""
    },
    model: {
      value: _vm.certificateValues.courseTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "courseTitle", $$v);
      },
      expression: "certificateValues.courseTitle"
    }
  })], 1), !_vm.isBulkEdit ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Fullt navn",
      "required": ""
    },
    model: {
      value: _vm.certificateValues.userFullName,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "userFullName", $$v);
      },
      expression: "certificateValues.userFullName"
    }
  })], 1) : _vm._e()], 1), !_vm.isBulkEdit ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "disabled": !_vm.certificateValues.showAttendance,
      "label": "Timer deltatt",
      "required": "",
      "type": "number"
    },
    model: {
      value: _vm.certificateValues.userAttendanceHours,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "userAttendanceHours", $$v);
      },
      expression: "certificateValues.userAttendanceHours"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": _vm.isBulkEdit ? 12 : 6,
      "md": _vm.isBulkEdit ? 12 : 6
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Timeomfang",
      "required": "",
      "type": "number"
    },
    model: {
      value: _vm.certificateValues.hoursWithInstructor,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "hoursWithInstructor", $$v);
      },
      expression: "certificateValues.hoursWithInstructor"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    staticStyle: {
      "padding-top": "30px"
    },
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Lokasjon",
      "items": _vm.locationItems,
      "rules": _vm.validateNotEmpty,
      "item-text": "label",
      "item-value": "value",
      "required": "",
      "dense": ""
    },
    model: {
      value: _vm.certificateValues.location,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "location", $$v);
      },
      expression: "certificateValues.location"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "4"
    }
  }, [_c('BaseDatePicker', {
    attrs: {
      "rules": _vm.validateNotEmpty,
      "label": "Startdato"
    },
    model: {
      value: _vm.certificateValues.courseStartDate,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "courseStartDate", $$v);
      },
      expression: "certificateValues.courseStartDate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "4"
    }
  }, [_c('BaseDatePicker', {
    attrs: {
      "label": "Sluttdato",
      "min": _vm.templateValues.courseStartDate
    },
    model: {
      value: _vm.certificateValues.courseEndDate,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "courseEndDate", $$v);
      },
      expression: "certificateValues.courseEndDate"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Vis fremmøte"
    },
    model: {
      value: _vm.certificateValues.showAttendance,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "showAttendance", $$v);
      },
      expression: "certificateValues.showAttendance"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Vis praksis"
    },
    model: {
      value: _vm.certificateValues.showCoursePractice,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "showCoursePractice", $$v);
      },
      expression: "certificateValues.showCoursePractice"
    }
  })], 1)], 1), _c('v-expansion-panels', {
    attrs: {
      "accordion": ""
    }
  }, [_c('v-expansion-panel', {
    attrs: {
      "disabled": !_vm.certificateValues.showCoursePractice
    }
  }, [_c('v-expansion-panel-header', [_vm._v("Praksis")]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions
    },
    model: {
      value: _vm.certificateValues.practice,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "practice", $$v);
      },
      expression: "certificateValues.practice"
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("Innhold")]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions
    },
    model: {
      value: _vm.certificateValues.curriculumContent,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "curriculumContent", $$v);
      },
      expression: "certificateValues.curriculumContent"
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("Læringsmål")]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions
    },
    model: {
      value: _vm.certificateValues.learningObjectives,
      callback: function callback($$v) {
        _vm.$set(_vm.certificateValues, "learningObjectives", $$v);
      },
      expression: "certificateValues.learningObjectives"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }