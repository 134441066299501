
import { api, binaryApi } from "@/api/api";
import {
  ApiCertificateStatus,
  ApiCourseDefaultVariablesDto,
  ApiGetCourseParticipantCertificateDto,
  ApiSendCourseParticipantCertificateDto,
} from "@/api/generated/Api";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { FilePreviewData } from "@/shared/helpers/fileViewHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import fileDownload from "js-file-download";
import EditCourseCertificateModal from "./EditCourseCertificateModal.vue";

export default defineComponent({
  name: "CourseCertificatesPage",
  components: {
    BaseTableFiltered,
    BaseLayout,
    EditCourseCertificateModal,
    BaseModal,
    CourseViewFileModal,
    BaseConfirmModalForm,
  },
  setup() {
    const selectedStudents = ref<ApiGetCourseParticipantCertificateDto[]>([]);
    const dialog = ref(false);
    const hasSelectedUsers = ref(false);
    const route = useRoute();
    const navigateBack = () => useNavigateBack(SingleCourseRouteNames.CourseDashboard);
    const store = useStore<StoreState>();
    const courseParticipants = ref<ApiGetCourseParticipantCertificateDto[] | null | undefined>([]);
    const courseDefaultVariables = ref<ApiCourseDefaultVariablesDto>();
    const courseCustomVariables = ref<ApiCourseDefaultVariablesDto>();
    const courseId = ref();
    const modalData = ref<ModalBaseData>({
      modalHeadline: "",
      modalType: ModalType.Edit,
      showModal: false,
      existingItem: courseParticipants,
      existingItemId: 0,
      defaultValues: courseDefaultVariables,
    });
    const filePreviewModalData = ref(getInitialModalData());
    const sendCertificateModalData = ref(getInitialModalData());
    const bulkEditCertificatesModalData = ref(getInitialModalData());
    const loading = ref<boolean>(false);

    const sendMethods = [
      {
        text: "Send til minsidebrukere",
        value: ApiCertificateStatus.ApiSent,
      },
      {
        text: "Send manuelt",
        value: ApiCertificateStatus.ApiSentManually,
      },
    ];

    const sendMethod = ref<ApiCertificateStatus>(sendMethods[0].value);

    const openCertificatesModal = useOpenModal(ModalType.Unspecified, "Utsted kursbevis", sendCertificateModalData);

    const openFilePreviewModalData = useOpenModal(ModalType.Edit, "", filePreviewModalData);

    const bulkEditCertificates = useOpenModal(ModalType.Edit, "kursbeviser", bulkEditCertificatesModalData);

    const checkHasSelectedUsers = () => {
      if (selectedStudents.value.length > 0) {
        hasSelectedUsers.value = true;
      } else if (selectedStudents.value.length === 0) {
        hasSelectedUsers.value = false;
      }
    };

    const getParticipantCertificatesList = async (wait = false) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        loading.value = true;
        courseParticipants.value = [];

        setTimeout(
          async () => {
            const response = (await api.course.getCourseParticipantCertificateList(+route.params.id)).data;
            courseId.value = response.courseId;
            courseParticipants.value = response.courseParticipants;
            courseDefaultVariables.value = response.courseDefaultVariables;
            courseCustomVariables.value = response.courseCustomVariables;

            loading.value = false;
          },
          wait ? 5000 : 0
        );
      });
    };

    const downloadCertificate = async (fileId: number, participantFullName: string) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const certificateFile = await binaryApi.file.downloadFileAsync(fileId, { format: "blob" });
        const fileName = `AOF-kursbevis-${participantFullName}`;
        fileDownload(certificateFile.data, fileName, certificateFile.data.type || undefined);
      });
    };

    const generateCertificates = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const payload: any = {
          userIds: selectedStudents.value.map((x) => x.userId),
        };

        await api.course.generateCourseParticipantCertificates(+route.params.id, payload);

        // This is to prevent the fetcing of users who has just had their certificateFileId
        // deleted by the call made above. This little delay will be sufficient for a course
        // that has not that many participants. If a course has many users. The timeout
        // won't work. So the user will have to manually reload the page.
        // This is just a temporary fix, hopefully we will find a more permanent solution.
        setTimeout(() => {
          getParticipantCertificatesList();
        }, 2000);
      });
    };

    const deleteCertificates = async () => {
      const payload: any = {
        userIds: selectedStudents.value.map((x) => x.userId),
      };

      try {
        await api.course.deleteCourseParticipantCertificates(+route.params.id, payload);
        openNotification(store, NotificationItemType.Success, "Kursbevis slettet");
        getParticipantCertificatesList();
      } catch (error) {
        openNotification(store, NotificationItemType.Error, "Sletting feilet");
      }
    };

    const sendCertificates = async () => {
      const payload: ApiSendCourseParticipantCertificateDto = {
        status: sendMethod.value,
        userIds: selectedStudents.value.map((x) => x.userId),
      };

      globalLoadingWrapper({ blocking: true }, async () => {
        await api.course.sendCourseParticipantCertificates(+route.params.id, payload);

        openNotification(store, NotificationItemType.Success, "Kursbevis sendt");
        sendCertificateModalData.value.showModal = false;
        getParticipantCertificatesList();
      });
    };

    const closeModal = () => {
      modalData.value.showModal = false;
    };

    const openEditCourseCertificate = useOpenModal(ModalType.Edit, "kursbevis", modalData);

    const headers = [
      { text: "Navn", value: "userFullName" },
      { text: "Handlinger", value: "actions", sortable: false },
      {
        text: "Kursbevis status",
        value: "certificateStatus",
      },
      {
        text: "Status",
        value: "participantStatus",
      },
      {
        text: "Kursmelding",
        value: "certificateMessage",
      },
      {
        text: "Fremmøteprosent",
        value: "attendancePercentage",
      },
    ];

    onMounted(async () => {
      await Promise.all([getParticipantCertificatesList()]);
    });

    return {
      selectedStudents,
      openEditCourseCertificate,
      checkHasSelectedUsers,
      hasSelectedUsers,
      navigateBack,
      course: computed(() => store.state.courses.course),
      dialog,
      courseParticipants,
      closeModal,
      modalData,
      filePreviewModalData,
      openFilePreviewModalData,
      headers,
      generateCertificates,
      downloadCertificate,
      courseId,
      sendCertificates,
      sendMethods,
      sendMethod,
      sendCertificateModalData,
      openCertificatesModal,
      bulkEditCertificates,
      getFilePreviewData,
      bulkEditCertificatesModalData,
      getParticipantCertificatesList,
      loading,
      deleteCertificates,
      courseCustomVariables,
    };
  },
});

const getFilePreviewData = (courseParticipantCertificate: ApiGetCourseParticipantCertificateDto): FilePreviewData => ({
  id: courseParticipantCertificate.certificateFileId as number, // Certificate file id is nullable but we have a check for that on the view file button.
  name: `Kursbevis for ${courseParticipantCertificate.userFullName}`,
  mimeType: "application/pdf",
});
